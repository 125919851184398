import { useAuth } from '@/context/Auth';
import { useCatalog } from '@/context/Catalog';
import { useFilters } from '@/context/Filters';
import { useWallet } from '@/context/Wallet';
import { toRem } from '@/helpers/toRem';
import WithFeaturesDisable from '@/hoc/WithFeaturesDisable';
import useMediaQuery from '@/hooks/useMediaQuery';
import { EyeIcon, FilterLinesIcon } from '@/theme/Icons';
import { DomainLicense } from '@/utils/multiDomains';
import {
  Box,
  Button,
  Container,
  HStack,
  Text,
  useDisclosure,
  VisuallyHidden,
  VStack
} from '@chakra-ui/react';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import { debounce } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CatalogFilter from '../Filter/CatalogFilter';
import NavBar from '../Navbar';
import SEO from '../SEO';
import { TournamentsHighLight } from '@/components/Tournaments';
import { SeoParams } from './CatalogWrapper';
import { TilesAll } from './TilesAll';
import { getTilesSectionsData, TilesSection } from './TilesSection';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useAppSettings } from '@/context/AppSettings';
import { hasHighlightedTournaments } from '@/helpers/tournaments';

const OriaBackground = dynamic(
  () => import('@/components/Videos/OriaBackground'),
  { ssr: false }
);
const DynamicCarouselBanner = dynamic(
  () => import('@/components/GamesCatalog/CarouselBanner')
);
const DynamicCategories = dynamic(
  () => import('@/components/GamesCatalog/Categories')
);
const DynamicCatalogBackground = dynamic(
  () => import('@/components/GoldenPalace/CatalogBackground')
);

export type SeoContentCatalog = {
  descriptionHeader?: string;
  descriptionLead?: string;
  descriptionContent?: string;
  metaDescription?: string;
  metaKeywords?: string;
  metaTitle?: string;
  name: string;
  slug: string;
};
export type GameCatalogWithFiltersTypes = {
  seoContent?: SeoContentCatalog;
  seoParams?: SeoParams;
  bannersCategory?: string;
};

const GameCatalogWithFilters = ({
  seoContent,
  seoParams,
  bannersCategory = 'all'
}: GameCatalogWithFiltersTypes) => {
  const { t, lang } = useTranslation();
  const { dataStore, licence } = useCatalog();
  const config = useAppSettings();

  const sections = useMemo(
    () =>
      getTilesSectionsData(
        dataStore?.sections || {},
        lang,
        licence as keyof DomainLicense
      ),
    [dataStore, lang, licence]
  );

  const { userData, countOfLoginAttempts, isLoginFetching } = useAuth();
  const { balance: userWalletBalance } = useWallet();
  const [isResized, setIsresized] = useState<number>(100);
  const { onOpen, onClose } = useDisclosure();
  const [nbOfImages, setNbOfImages] = useState<number>(4);
  const isMobile = useMediaQuery('(max-width: md)');
  const observeRef = useRef<HTMLDivElement>(null);

  const [filterVisibility, setFilterVisibility] = useLocalStorage(
    'filterVisibility',
    false
  );

  const {
    filteredGames,
    numberOfFilterSectionsActive,
    hasUpdatedCategory,
    filterFields: {
      category: { name: selectedCategory }
    },
    resetAll
  } = useFilters();

  const seo = {
    description: seoContent?.metaDescription
      ? seoContent?.metaDescription
      : t('catalog:leMeilleurCatalogue'),
    keywords: seoContent?.metaKeywords ? seoContent?.metaKeywords : '',
    title: seoContent?.metaTitle
      ? seoContent?.metaTitle
      : t('catalog:jeuxDeCasino')
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefresh = useCallback(
    debounce(() => setIsresized(window?.performance.now()), 1000),
    []
  );

  // useEffect(() => {
  //   const inViewport = (
  //     entries: IntersectionObserverEntry[],
  //     observer: IntersectionObserver
  //   ) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         // if (entry.target.id === 'bgVideo') {
  //         //   // play or pause video depend of visibility of the video on the page
  //         //   if (entry.intersectionRatio < 0.3) {
  //         //     (entry.target as HTMLVideoElement).pause();
  //         //   } else {
  //         //     (entry.target as HTMLVideoElement).play();
  //         //   }
  //         // }
  //         if (entry.target.id !== 'bgVideo') {
  //           entry.target.classList.add('is-inViewport');
  //         }
  //       }
  //     });
  //   };

  //   const Obs = new IntersectionObserver(inViewport, {
  //     threshold: [0, 0.25, 0.5, 0.75, 1]
  //   });

  //   // document.queryselectoAll return an empty NodeList in some context, so use this hack to "delay" initialization of Observer
  //   const delayedObserver = setTimeout(() => {
  //     const elementsInViewport = document.querySelectorAll('[data-inviewport]');
  //     elementsInViewport.forEach((element) => {
  //       Obs.observe(element);
  //     });
  //     // const bgVideo = document.getElementById('bgVideo');
  //     // bgVideo && Obs.observe(bgVideo as HTMLVideoElement);
  //   }, 0);

  //   return () => {
  //     Obs.disconnect();
  //     clearTimeout(delayedObserver);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isResized]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debouncedRefresh);

    resizeObserver.observe(document?.body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize filterVisible state based on localStorage
  const onToggleFilter = () => {
    setFilterVisibility((visibility) => !visibility);
  };

  // set number of images by row depending of filter if is open or not
  useEffect(() => {
    filterVisibility ? setNbOfImages(4) : setNbOfImages(5);
  }, [filterVisibility]);

  const showTournaments =
    config.isTournamentEnabled && hasHighlightedTournaments(dataStore);

  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        schemaType="Game"
        robotsContent={seoParams?.robots ?? 'all'}
      />
      <NavBar />
      {WithFeaturesDisable(<OriaBackground />, 'OriaBackground')}
      {WithFeaturesDisable(<DynamicCatalogBackground />, 'CatalogBackground')}

      <VisuallyHidden>
        <Text as="h1">
          {t('catalog:jeuxDeCasino')}
          <br />
          {t('catalog:leMeilleurCatalogue')}
        </Text>
      </VisuallyHidden>

      <Container maxW="container.xl" padding={['0', '1rem']}>
        <VStack
          paddingTop="7.5rem"
          zIndex="100"
          position="relative"
          width={['100%']}
          paddingLeft={['1rem', 0]}
          align="baseline"
        >
          <VisuallyHidden>
            <Text as="h1" fontSize="xl">
              {t('catalog:games')}
            </Text>
          </VisuallyHidden>
          {seoContent?.descriptionHeader && (
            <Text
              as="h2"
              textStyle="h1"
              marginBottom={'1rem'}
              textAlign={'center'}
              width={'100%'}
            >
              {seoContent?.descriptionHeader}
            </Text>
          )}
          <DynamicCategories
            data={dataStore?.categories || {}}
            observeRef={observeRef}
          >
            {null}
          </DynamicCategories>
          {seoContent?.descriptionLead && (
            <Text
              as={'h2'}
              textStyle="h2"
              padding={'1rem 0'}
              textAlign={'center'}
              width={'100%'}
            >
              {seoContent?.descriptionLead}
            </Text>
          )}
          <DynamicCarouselBanner categoryId={bannersCategory} />
        </VStack>
        {/* anchor to catalog */}
        <div id="catalog"></div>
        {!isMobile ? (
          <HStack
            justifyContent={'space-between'}
            alignItems="center"
            paddingY={'1rem'}
            borderColor="lightWhite"
            borderBottom={'1px solid'}
            marginBottom="3.5rem"
            marginTop={'3.5rem'}
          >
            <Text fontSize={toRem(18)}>
              {filteredGames?.length} {t('catalog:games')}
            </Text>
            <Text
              onClick={onToggleFilter}
              cursor="pointer"
              display={'flex'}
              alignItems="center"
              fontSize={toRem(18)}
            >
              <FilterLinesIcon fontSize={'1.5rem'} />
              {filterVisibility
                ? t('catalog:hideFilter')
                : t('catalog:showFilter')}
              {numberOfFilterSectionsActive > 0 && !filterVisibility ? (
                <Button variant="outline" size="xs" marginLeft={'.5rem'}>
                  {numberOfFilterSectionsActive}
                </Button>
              ) : null}
            </Text>
          </HStack>
        ) : null}
        <Box
          display={['grid']}
          gridGap="3.5rem"
          marginTop={'2rem'}
          gridTemplateColumns={
            filterVisibility && !isMobile ? '18% auto' : 'auto'
          }
          padding={isMobile ? '0 1rem' : filterVisibility ? '0 0 0 1rem' : '0'}
        >
          {!isMobile ? (
            <CatalogFilter
              isOpen={filterVisibility}
              onOpen={onOpen}
              onClose={onClose}
            />
          ) : null}
          <Box
            width={'100%'}
            maxWidth={'100%'}
            overflow={'hidden'}
            padding={'1rem 0'}
          >
            {sections?.map((section) => (
              <TilesSection
                key={section.id}
                section={section.id}
                title={section.title}
                icon={section.icon}
                seeAllRoute={section.url}
                nbOfImages={nbOfImages}
                isFilterOpen={filterVisibility}
                mb={8}
              />
            ))}

            {/* Tournaments */}
            {showTournaments ? (
              <TournamentsHighLight
                pr={[0, 0]}
                mt={12}
                mb={12}
                css={{
                  '&:first-of-type': {
                    marginTop: 0
                  }
                }}
              />
            ) : null}

            <TilesAll
              title={t('catalog:allGames')}
              iconSection={EyeIcon}
              nbOfImages={isMobile ? 2 : nbOfImages}
              isFilterOpen={filterVisibility}
            />
          </Box>
        </Box>

        {/* content */}
        <Prose mt={16} px={['1rem', 0]}>
          <Box
            className="seoContent"
            dangerouslySetInnerHTML={{
              __html: seoContent?.descriptionContent || ''
            }}
          ></Box>
        </Prose>
      </Container>
    </>
  );
};
export default GameCatalogWithFilters;
