import { Box, SlideDirection } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import Filters from '../GamesCatalog/Filters';

type UserMenuProps = PropsWithChildren & {
  placement?: SlideDirection;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const CatalogFilter = ({
  placement = 'left',
  children,
  onClose,
  onOpen,
  isOpen
}: UserMenuProps) => {
  return isOpen ? (
    <Box display={['none', 'flex']} width="100%">
      {children}
      <Filters />
    </Box>
  ) : (
    <>{children}</>
  );
};

export default CatalogFilter;
